import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';
import { useLocation } from '@reach/router';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function useShiftUrl() {
  const location = useLocation();
  const shift = getQueryValue(location, 'shift');
  const finalShift = decodeURIComponent(shift);

  return finalShift;
}

export default useShiftUrl;